import React from "react"

function SvgComponent(props) {
  const themeName = props.themeName || 'light'
  const fillColor = (themeName === 'dark') ? '#FFFFFF' : '#000000'
  return (
    <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 100.02 37.05"
    {...props}
    style={{
      maxWidth: "120px",
      width: "120px"
    }}
  >
    <defs>
      <style>
        {".prefix__cls-1{fill:#ce0000;stroke:#231f20;stroke-miterlimit:10}"}
      </style>
    </defs>
    <g id="prefix__Layer_2" data-name="Layer 2">
      <g id="prefix__Layer_1-2" data-name="Layer 1">
        <path
          className="prefix__cls-1"
          d="M17.77 28.6c0 4.31-.52 7.95-6.29 7.95a19.93 19.93 0 01-2.55-.21v-4a13.34 13.34 0 001.66.1c1.88 0 2.55-.52 2.55-3.27V1.4h4.63zM36.7 17.36V28c0 5.72-2.81 8.53-7.54 8.53s-7.44-2.81-7.44-8.47V17.21c0-5.57 3.07-8.22 7.59-8.22C34 9 36.7 12 36.7 17.36zm-10.3-.26v11.13c0 2.5.78 4.16 2.86 4.16 1.87 0 2.74-1.39 2.74-4.21V17.1c0-2.28-.68-4-2.81-4-1.9 0-2.79 1.46-2.79 4zM47.1 28.6c0 4.31-.52 7.95-6.29 7.95a19.93 19.93 0 01-2.55-.21v-4a13.34 13.34 0 001.66.1c1.88 0 2.55-.52 2.55-3.27V1.4h4.63zM66 17.36V28c0 5.72-2.81 8.53-7.54 8.53s-7.44-2.81-7.44-8.47V17.21c0-5.57 3.07-8.22 7.6-8.22S66 12 66 17.36zm-10.3-.26v11.13c0 2.5.78 4.16 2.86 4.16 1.88 0 2.76-1.35 2.76-4.21V17.1c0-2.28-.68-4-2.81-4-1.89 0-2.78 1.46-2.78 4zM74.89 27.92v1.25c0 2 .68 3.43 2.5 3.43 1.66 0 2.39-1 2.39-3.12s-.63-3.28-3.8-5.36c-3.64-2.34-5.35-4.26-5.35-8.06 0-4.26 2.23-7.06 6.76-7.06 4.88 0 6.7 2.86 6.7 7.39v1.14h-4.52V16c0-2-.62-3-2.18-3s-2.14 1-2.14 2.7.58 2.5 3.12 4.16c4.37 2.81 6.09 4.68 6.09 9.1 0 5-2.39 7.59-7.23 7.59-5.72 0-7.07-3.64-7.07-7.43v-1.2z"
        />
        <path
          d="M98.71 23.27l-4.28-1.42H4.22a2 2 0 00-1.94-1.36 2.07 2.07 0 101.94 2.78z"
          fill="#dbdbdb"
          strokeWidth={0.43}
          stroke="#231f20"
          strokeMiterlimit={10}
        />
        <path
          className="prefix__cls-1"
          d="M66.67 3.47c-.73.73-2.81 5.93 1.6 5.93S71 3 67.88 1.09c.68 2.37-.42 3.63-.6 3.63-.34-.09-.58-.83-.61-1.25z"
        />
      </g>
    </g>
  </svg>  )
}

export default SvgComponent
