
import * as Components from "./Jojos"

export default function getCustomComponent () {
  return Components
}

export function getShopId () {
  return "jojos"
}

