import React, { Component, PureComponent, useContext, useState } from 'react';
import {NavLink, Link, Switch, Route, Redirect} from 'react-router-dom'
import {RootRouterContext} from '../../index.js'
import OurStoryPage from './OurStoryPage.js'
import Catering from './Catering.js'
import Taconight from './Taconight.js'
import Order from './Order.js'
import Menu from './Menu.js'
import OrderAltadena from './OrderAltadena.js'
import Headers from './Headers.js'
import AutoMenu from '../../react/AutoMenu'
// import AutoMenu from '../../react/AutoMenu'
import CartBar from './CartBar.js'
import ThemeToggle from './ThemeToggle/index.js'
import ThemeContext from '../../Context/ThemeContext.js'
import LogoHero from './svg/LogoHero.js'
import FormJob from './FormJob.js'
import ScrollToTop from '../../react/ScrollToTop.js';

import './index.scss'
import './ThemeToggle/theme_night.scss'

export const customOrderAppPaths = ["/order", "/menu"]
export const StickyTopBar = () => { return null }

export const locations = [
  {shopId: "jojos",     basePath: "/westhollywood", cssGlobalPrefix: 'jojos'},
  {shopId: "jojosnoho",     basePath: "/noho", cssGlobalPrefix: 'jojos'},
  {shopId: "kluckinla",    basePath: "/la", cssGlobalPrefix: 'kokorollburbank'},
  {shopId: "kluckinla",    basePath: "/sunland", cssGlobalPrefix: 'kokorollburbank'},
  {shopId: "kluckinpa",    basePath: "/pasadena", cssGlobalPrefix: 'kokorollburbank'},
  {shopId: "kluckinre",    basePath: "/reseda", cssGlobalPrefix: 'kokorollburbank'},
  {shopId: "kluckinoaks",    basePath: "/shermanoaks", cssGlobalPrefix: 'kokorollburbank'},
  {shopId: "kluckinsd",    basePath: "/sandiego", cssGlobalPrefix: 'kokorollburbank'},
  {shopId: "kluckinva",    basePath: "/valencia", cssGlobalPrefix: 'kokorollburbank'},
  {shopId: "kluckinva",    basePath: "/santaclarita", cssGlobalPrefix: 'kokorollburbank'},
  // {shopId: "kokorollhollywood",    basePath: "/hollywood", cssGlobalPrefix: 'kokorollburbank'},
]

export const pages = [
  {path: "/order", label: "Order"},
  {path: "/menu", label: "Menu"},
  // {path: '/jobs', label: 'Jobs', component: ()=> <FormJob></FormJob> },

  {path: "/hqusers/storeRedirect/:anything", label: "Catering", hideFromNav: true, component: () => <Redirect to="/"></Redirect>},
  // {path: "/taco-night", label: "Event", component: Taconight},
  // {path: "/order-altadena", label: "Menu", component: OrderAltadena},
]

export function SiteNav ({shopId}) {

    const {rootHistory} = useContext(RootRouterContext)


    return (
      <>
      <CartBar shopId={shopId}></CartBar>
    <nav role="navigation" aria-label="navigation">
    
    <div className="pages-nav" >
      <ul aria-label="more links">
        <li><a className="page-link" href="/" onClick={(e) => {
          e.preventDefault()
          rootHistory.push('/')
        }}>All Locations</a></li>
        {pages
          .filter(({hideFromNav}) => !hideFromNav)
          .map(({path, label, external, onClick}) => (
            <li key={path}>
              {external ?
                <a       className="page-link" href={path}>{label}</a> :
                <NavLink className="page-link"
                
                exact to={path}>{label}</NavLink>}
            </li>
        ))}
      </ul>
      {/* <ThemeToggle /> */}
    </div>
  </nav>
  </>
  )
}

export function MultilocationHome () {
  // var preferredTheme = 'dark';
  // try {
  //   preferredTheme = localStorage.getItem('theme') || preferredTheme;
  // } catch (err) { }
  // const [theme, setTheme] = useState(preferredTheme)
  // window.__preferredTheme = theme
  // window.__setPreferredTheme = function (newTheme) {
  //   console.log('setting: ', newTheme)
  //   setTheme(newTheme)
  //   try {
  //     localStorage.setItem('theme', newTheme);
  //   } catch (err) {}
  // }
  // const {themeName: theme} = useContext(ThemeContext)
  const theme = "light"
  const heroLogoUrl = (theme !== 'dark') ?
    "https://afag.imgix.net/the-kluckin-chicken/kluckin-chicken-logo-1.png?w=500&auto=format" :
    "https://afag.imgix.net/the-kluckin-chicken/kluckin-chicken-logo-1.png?w=500&auto=format"

  return(
    <div className={"site-content kokorollburbank" + " " + theme}>
      <Headers></Headers>
      <div className="home-page-hero" role="banner" aria-labelledby="hero-link">
        <div>
          <Link to="/" aria-label="Kluckin Chicken" id="hero-link" className="hero-link">
            <h1 id="location-header">

              <LogoHero
                theme={theme}
                ></LogoHero>
            </h1>
            {/* <img
              className="hero-logo"
               src={heroLogoUrl} alt={"Kluckin Chicken"}/> */}
          </Link>
        </div>
      </div>

      {/* <div className="promo">
        <div style={{
          maxWidth: "400px"
        }} className="figure">

        <img style={{width: "100%"}} src="https://afag.imgix.net/the-kluckin-chicken/kluckin-ten.png?w=1200&auto=format" alt="" />
        </div>
      </div> */}
  
      <Switch>
        <Route path="/order" component={Order} />
        <Route path="/online-ordering" component={Order} />
        <Route path="/menu" component={Order} />
        <Route path="/about-us" component={Order} />
        <Route path="/locations" component={Order} />
        <Route path="/jobs" component={FormJob} />
        <Route path="/taco-night" component={Taconight} />
        <Route path="/" exact component={MultiHeader}></Route>
        <Route path="/hqusers/storeRedirect/:anything" render={() => {
          return(<Redirect to="/"></Redirect>)
        }}></Route>
        {/* <MultiHeader></MultiHeader> */}
      </Switch>
    </div>
  )
}

function ContactPage () {
  return(
    <div className="contact-page">
      <div className="contact-page-content">
        <h2>How Are We Doing?</h2>
        <p>
        We value your feedback at Kluckin Chicken. If you have recently visited one of our locations, please take a moment to call our corporate office <a href="tel:+19195823222">818-582-3222</a> and let us know how we did. Your input helps us improve our service and the overall customer experience. Thank you for choosing Kluckin Chicken!
        </p>
      </div>


    </div>
  )
}

class MultiHeader extends PureComponent {
  render() {
    return (
      <div className="landing-page-wrapper" >
        <div className="home-grid">

          <div id="pick-locations" role="main" aria-labelledby="location-header">
            <div className="location-header">
              {/* <div>
                <ThemeToggle></ThemeToggle>
              </div> */}
              
              <h2>Locations</h2>
            
            </div>

            {/* <div className="special">
              <a href="/public/kluckinall/kluckin-chicken-print-coupon.pdf">
                <img 
                style={{
                  width: "100%",
                  maxWidth: "550px",
                  display: "block",
                  margin: "1em auto",
                }}
                src="https://afag.imgix.net/the-kluckin-chicken/promo-banner-1.png?w=900&auto=format" alt="" />
              </a>
            </div> */}

            {/* <div className="hiring">
              <Link className="order-button hire" to="/jobs">We're Hiring →</Link>
            </div> */}

            <div className="location-list">{locationsMetadata.map(({id, disabled, buttonLabel, openingDate, label, address, phone, hours, phoneLink, orderLink, className="", external}) => (
                <div className={"grid-item location " + className}>
                  <div className="location-list-content">
                    <h3 className="title">{label}</h3>

         
                    
         

                    {disabled ?
                      <Link to="#" className="order-location-link"><span className="order-button disabled">{openingDate}</span></Link> :
                      <a href={orderLink} 
                      aria-label={"Order Pickup or Delivery for " + label + " location"}
                      className="order-location-link">
                      <span className="order-button">Order Pickup / Delivery</span></a>
                    }

                    
                    {!disabled && <div className="loc-menu-link">
                      <Link 
                      aria-label={"Menu for " + label + " location"}
                      to={orderLink} className="menu-location-link"><span className="menu-button">{"menu"}</span></Link>
                    </div>}

                    {/* <p>{external ? 
                    <a href={orderLink} className="order-location-link">
                    <span className="order-button">Order Pickup / Delivery</span></a> :
                    <Link to={orderLink} 
                      aria-label={"Order Pickup or Delivery for " + label + " location"}
                      className="order-location-link">
                      <span className="order-button">Order Pickup / Delivery</span></Link>
                      }
                    </p> */}
                    <div className="address">
                      {address[0]} <br/>
                      {address[1]}
                    </div>
                    <div className="phone">
                      <a
                        className="phone-link"
                        href={`tel:${phoneLink}`}>{phone}</a>
                    </div>
                    <ul className="hours-of-operation">
                      {hours.map((hours) => {
                        return (<li key={hours.day}>
                          <span className="">{hours.day}: {hours.hours}</span>
                          {/* <span className="hours">{hours.hours}</span> */}
                        </li>)
                      })}
                    </ul>

              
                  </div>
                </div>
              ))}
            </div>
            
            {/* <ContactPage></ContactPage> */}
                    </div>
          </div>

      </div>
    );
  }
}




export class Header extends PureComponent {
  render() {
    // console.log('header props:', this.props)
    const shopId = this.props.shopId
    const basePath = this.props.basePath

    
    return (
      <div className="location-page">
        {/* <ScrollToTop ></ScrollToTop> */}
        <div className="top-banner" style={{
          backgroundImage: "url(https://afag.imgix.net/jojos-mediterranean-grill/ghallaba-chicken.jpg?w=800&auto=format)",
          backgroundColor: "rgb(50,50,50)",
          // background: "url(https://afag.imgix.net/jojos-mediterranean-grill/lamb-chops.jpg?w=800&auto=format), linear-gradient(180deg, rgba(238,143,33,1) 0%, rgba(226,90,39,1) 100%)",
          backgroundSize: "contain"
        }}>

          
        <div role="main" aria-label="location information" className="info header">
          {locationsMetadata
            .filter((l) => l.id === this.props.shopId)
            // .slice(0,1)
            .map(({label, address, phone, hours, special, phoneLink, className=""}) => (
              <div className={"location"}>
                
                <div className="content">
                  {/* <h2>Kokoroll {label}</h2> */}
                  {address && <div aria-label="location address" className="address">
                    <p>
                      Address: <br/>
                      {address[0]} <br/>
                      {address[1]}
                    </p>
                  </div>}
                  {phone && <div
                   aria-label="location phone number"
                   className="phone">
                    Phone: <a
                      className="phone-link"
                      href={`tel:${phoneLink}`}>{phone}</a>
                  </div>}
                  {hours && <p 
                   aria-label="location hours of operation"
                  className="hours-of-operation">
                    {hours.map((hours) => {
                      return (<div 
                      
                      key={hours.day}>
                
                          <span className="">{hours.day}: {hours.hours}</span>
                          {/* <span className="hours">{hours.hours}</span> */}
                    
                      </div>)
                    })}
                  </p>}
                  {/* <div 
                   aria-label="location online services"
                  className="order-action">
                    <a href="#order" className="order-button">Order / Menu</a>
                  </div> */}

                  
                </div>
              </div>
          ))}




          </div>
        </div>

       

        {/* <div className="special">
          <a href="/public/kluckinall/kluckin-chicken-print-coupon.pdf">
            <img 
            style={{
              width: "100%",
              maxWidth: "550px",
              display: "block",
              margin: "1em auto",
            }}
            src="https://afag.imgix.net/the-kluckin-chicken/promo-banner-1.png?w=900&auto=format" alt="" />
          </a>
        </div> */}

  
      <h2 id="order" style={{visibility: 'hidden', height: "0px", margin: "0", padding: "0"}}>Order Online</h2>

        <div className="ordering-menu">
          <AutoMenu
            subMenuFilter={subMenuFilter}
            width={500}></AutoMenu>
        </div>
      </div>
    );
  }
}



let locationsMetadata = [
  {
    id: 'jojos',
    // disabled: true, openingDate: "visit/call to order",
    label: "West Hollywood",
    className: 'night',
    address: ["8000 Sunset Blvd Suite B130", "WEST HOLLYWOOD 90046"],
    phone: "(323) 822-5555",
    phoneLink: "+13238225555",
    orderLink: "/westhollywood",
    hours: [
      { day: 'Mon-Sat', hours: '10am-10pm' },
      { day: 'Sunday', hours: '11am-10pm' },

    ]},
  {
    id: 'jojosnoho',
    label: "North Hollywood",
    // special: "We Moved!",
    address: ["5200 Lankershim Blvd Ste 110", "North Hollywood, CA 91601"],
    phone: "8183584500",
    phoneLink: "+18183584500",
    orderLink: "/noho",
    // external: true,
    hours: [
      // { day: 'Mon-Sun', hours: '11am-3am' },

    ]},
]

export function subMenuFilter (subMenu) {
  const tag = subMenu.tag
  if (!tag) {return true}

  const now = new Date()
  const day = now.getDay() // Sunday - Saturday : 0 - 6
  const hours = now.getHours() //0 to 23
  const minutes = now.getMinutes() // 0 to 59
  const isWeekday = [1,2,3,4,5].indexOf(day) !== -1
  const isDinner = (
    (hours >= 16) && (hours <= 22)
  )
  const isLunch = (
    (hours >= 11) && (hours < 16)
  )

  const showDinnerMenu = isDinner
  const showLunchMenu = isLunch && isWeekday

  const isLunchSubmenu = tag.indexOf('lunch') !== -1
  const isDinnerSubmenu = tag.indexOf('dinner') !== -1

  if (isLunchSubmenu) {
    if (isWeekday) {
      if (hours < 16) {
        return true
      }
    }
    return false
  }

  return true
}
