import React, {useContext} from 'react'
import {Link} from 'react-router-dom'
import PropTypes from 'prop-types'

import { Subscribe } from 'unstated'
import {injectCartContainer} from '../../react/CartContainer'
import {injectShopContainer} from '../../react/ShopDataContainer'
import LogoBar from "./svg/LogoBar"
import ThemeContext from '../../Context/ThemeContext'
// import {injectCustomComponents} from '../CustomComponentsProvider'
// import {getRestaurantId} from '../../utils'

// import Image from '../../react/Image'
// import makeCloudinaryUrlForPhoto from './makeCloudinaryUrlForPhoto'

function CartMinimized ({cart, shop, settings, shopId}) {
  // const {themeName: theme} = useContext(ThemeContext)
  const theme = "light"
  if (!shop) {return null}
  let items = cart.state.products
  let numberOfItems = items.length || 0

  // console.log(shop.name)
  // const logoImage = makeCloudinaryUrlForPhoto(logo, {height: 200})
  const cashback = cart.state.cashback
  const showCashback = Number(cashback) && (cashback > 0)


  return (
     <div role="navigation" aria-label="primary" className="cart-wrapper">

      <div className="cart minimized">
        <div className="home-link-wrapper">
          <h2 style={{
            display: 'flex',
            alignItems: 'flex-end',
          }}
          >
            <Link to="/" 
            aria-label={shop.name && shop.name.en}
            className="home-link" aria-label="home page">
                {/* <img
              className="nav-bar-logo"
              src={"https://afag.imgix.net/the-kluckin-chicken/kluckin-chicken-logo-1.png?w=500&auto=format"}
              alt="Kluckin Chicken"
            /> */}
              <LogoBar
              alt={shop.name && shop.name.en}
              shopId={shopId} theme={(theme === 'dark') ? 'light' : 'dark'}></LogoBar>

              
              {/* <span className="text-logo">{!logo && shop.name && shop.name.en}</span> */}
            </Link>

            <div style={{
                color: 'black',
                margin: "5px",
                padding: '5px 0 0px 10px',
                borderLeft: '3px solid black',
                textTransform: 'uppercase',
                fontWeight: '900',
            
                
                
              }} className="location-name">
                {(shopId === 'jojos') ? <span className="location-name">West<br/>Hollywood</span> : <span className="location-name">North<br/>Hollywood</span>}
              </div>
          </h2>

          

        </div>



        <div className="tool-bar" aria-labelledby="shopping-cart-link">
          {/* {showCashback ? <span className="cashback-amount">
            ${cashback / 100}
          </span> : null} */}
          <Link
          id="shopping-cart-link"
            aria-label="shopping cart"
            aria-label="shopping cart"
            to={{pathname: "/checkout", state: {fromMini: true}}}
            className="checkout-button button">cart ({numberOfItems})</Link>
        </div>



      </div>
    </div>
  )
}




const CartIcon = () => <svg xmlns="http://www.w3.org/2000/svg" className="cart-icon" viewBox="0 0 24 24" version="1" width="50" height="50">
  <path d="M20 20v-8h2L12 3 2 12h2v8l1 1h5v-7h4v7h5l1-1z"/>
</svg>

export default (injectCartContainer(injectShopContainer(CartMinimized)))
